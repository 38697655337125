import * as S from "./styles";

const SocialMedia = ({ isMobile }) => {
  return (
    <S.SocialMedia isMobile={isMobile}>
      <S.SocialMediaWrapper isMobile={isMobile}>
        {!isMobile ? <S.SocialMediaHeader></S.SocialMediaHeader> : ""}

        <S.SocialMediaButton
          href="https://discord.com/invite/solderland"
          target="_blank"
          isMobile={isMobile}
        >
          <S.DiscordButton></S.DiscordButton>
        </S.SocialMediaButton>

        <S.SocialMediaButton
          href="https://instagram.com/solderland_nft"
          target="_blank"
          isMobile={isMobile}
        >
          <S.InstagramButton></S.InstagramButton>
        </S.SocialMediaButton>

        <S.SocialMediaButton
          href="https://twitter.com/solderland"
          target="_blank"
          isMobile={isMobile}
        >
          <S.TwitterButton></S.TwitterButton>
        </S.SocialMediaButton>
      </S.SocialMediaWrapper>
    </S.SocialMedia>
  );
};

export default SocialMedia;
