import React, { useLayoutEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./styles/global.css";

import Loading from "components/Loading";
import Home from "pages/Home";
import Story from "pages/Story";
import Stake from "pages/Stake";
import Beta from "pages/Beta";
import WhitePaper from "pages/Whitepaper";
import Rarity from "pages/Rarity";
import Collab from "pages/Collab";
import Maintenance from "pages/Maintenance";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import { mobileSize } from "variables";
// import "swiper/swiper.scss";

function App() {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [mobile, setMobile] = React.useState<boolean>(false);

  useLayoutEffect(() => {
    window.addEventListener("load", () => {
      setLoading(false);
    });
  }, []);

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth < mobileSize) setMobile(true);
      else setMobile(false);
    }
    window.addEventListener("resize", updateSize);

    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  return loading ? (
    <Loading />
  ) : (
    <Routes>
      {/* <Route path="/" element={<Maintenance isMobile={mobile} />}></Route> */}
      <Route path="/" element={<Home isMobile={mobile} />}></Route>
      <Route path="/story" element={<Story isMobile={mobile} />}></Route>
      <Route path="/stake" element={<Stake isMobile={mobile} />}></Route>
      <Route path="/beta" element={<Beta />}></Route>
      <Route
        path="/whitepaper"
        element={<WhitePaper isMobile={mobile} />}
      ></Route>
      <Route path="/rarity" element={<Rarity isMobile={mobile} />}></Route>
      <Route path="/collab" element={<Collab isMobile={mobile} />}></Route>
    </Routes>
  );
}

export default App;
