import * as S from "./styles";
import { Title } from "styles/Global.styles";
import Container from "components/Container";

import Collapse from "components/sections/Faq/Collapse";

import { faq } from "variables";

const Faq = () => {
  return (
    <S.Section id="faq">
      <Container>
        <Title>Faq</Title>
        <S.FaqArea>
          {faq.map((item, index) => (
            <Collapse
              question={item.question}
              answer={item.answer}
              key={index}
            ></Collapse>
          ))}
        </S.FaqArea>
      </Container>
    </S.Section>
  );
};

export default Faq;
