import styled from "styled-components";

export const Section = styled.section`
  position: relative;
  text-align: center;
  margin-top: 85px;
  width: 100%;
  z-index: 2;
`;

export const FaqArea = styled.div``;
