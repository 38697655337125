import * as S from "./styles";
import { Title } from "styles/Global.styles";
import Container from "components/Container";

import { roadmap } from "variables";

import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

const Roadmap = ({ isMobile }) => {
  return isMobile ? (
    <RoadmapSlide></RoadmapSlide>
  ) : (
    <RoadmapNormal></RoadmapNormal>
  );
};

const RoadmapNormal = () => {
  return (
    <S.Section id="roadmap">
      <Container>
        <Title>RoadMap</Title>
        {/* <S.Buttons>
          <S.Button active={true}>Pre-launch</S.Button>
          <S.Button active={false}>launch</S.Button>
          <S.Button active={false}>Future</S.Button>
        </S.Buttons> */}
        <S.RoadmapArea>
          {roadmap.map((item, index) => (
            <S.RoadmapItem key={index} active={item.active}>
              <S.RoadmapTitle bold>{item.date}</S.RoadmapTitle>
              {item.toDoList.map((todo, index) => (
                <S.RoadmapDescription key={index}>
                  ~ {todo}
                </S.RoadmapDescription>
              ))}
            </S.RoadmapItem>
          ))}
        </S.RoadmapArea>
      </Container>
    </S.Section>
  );
};

const RoadmapSlide = () => {
  return (
    <S.Section id="roadmap">
      <Container>
        <Title>RoadMap</Title>
        {/* <S.Buttons>
          <S.Button active={true}>Pre-launch</S.Button>
          <S.Button active={false}>launch</S.Button>
          <S.Button active={false}>Future</S.Button>
        </S.Buttons> */}
        <Swiper
          modules={[Navigation]}
          navigation
          slidesPerView={1}
          slidesPerGroup={1}
          pagination={{ clickable: true }}
        >
          <S.RoadmapArea>
            {roadmap.map((item, index) => (
              <SwiperSlide key={index}>
                <S.RoadmapItem active={item.active}>
                  <S.RoadmapTitle bold>{item.date}</S.RoadmapTitle>
                  {item.toDoList.map((todo, index) => (
                    <S.RoadmapDescription key={index}>
                      ~ {todo}
                    </S.RoadmapDescription>
                  ))}
                </S.RoadmapItem>
              </SwiperSlide>
            ))}
          </S.RoadmapArea>
        </Swiper>
      </Container>
    </S.Section>
  );
};

export default Roadmap;
