import * as S from "./styles";
import { menu } from "variables";
import React from "react";

import { Burger, Menu } from "components/LeftSidebar";

const DesktopHeader = () => {
  return (
    <S.Header>
      <S.HeaderContainer>
        {menu.map((item, index) =>
          !item.route ? (
            <S.MenuLink
              href={item.href}
              key={index}
              soon={item.soon}
              target={item.target ? "_blank" : ""}
            >
              {item.title}
            </S.MenuLink>
          ) : (
            <S.RouteLink soon={item.soon} to={item.href} key={index}>
              {item.title}
            </S.RouteLink>
          )
        )}
      </S.HeaderContainer>
    </S.Header>
  );
};

const MobileHeader = () => {
  const [open, setOpen] = React.useState<boolean>(false);
  const menuId = "mobile-menu";

  return (
    <div>
      <Burger open={open} setOpen={setOpen} aria-controls={menuId} />
      <Menu open={open} setOpen={setOpen} id={menuId} />
    </div>
  );
};

const Header = ({ isMobile }) => {
  return isMobile ? <MobileHeader /> : <DesktopHeader />;
};

export default Header;
