import styled, { css } from "styled-components";
import { Link } from "react-router-dom";

export const Header = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 30px 15px;
  width: 100%;
  z-index: 3;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-around;
  color: white;
  width: 70%;
`;

export const MenuLink = styled.a<{ soon: any }>`
  position: relative;
  color: white;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;

  ${(props) =>
    props.soon &&
    css`
      &::after {
        position: absolute;
        content: "soon";
        top: -10px;
        right: -40px;
        font-size: 9px;
        padding: 2px 4px;
        background: #2cd0ff;
        border-radius: 5px;
      }
    `}
`;

export const RouteLink = styled(Link)<{ soon: any }>`
  position: relative;
  color: white;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;

  ${(props) =>
    props.soon &&
    css`
      &::after {
        position: absolute;
        content: "soon";
        top: -10px;
        right: -40px;
        font-size: 9px;
        padding: 2px 4px;
        background: #2cd0ff;
        border-radius: 5px;
      }
    `}
`;
