import React from "react";
import * as S from "./styles";
import AnimateHeight from "react-animate-height";

const Collapse = ({ question, answer }) => {
  const [active, setActive] = React.useState<boolean>(false);

  return (
    <S.CollapseArea onClick={() => setActive(!active)}>
      <S.CollapseHeader>
        <S.CollapseTitle>{question}</S.CollapseTitle>
        <S.CollapseIcon active={active}></S.CollapseIcon>
      </S.CollapseHeader>
      <AnimateHeight duration={500} height={active ? "auto" : 0}>
        <S.CollapseContent>
          {" "}
          {answer.split("\n").map((item, index) => {
            return <p key={index}>{item}</p>;
          })}{" "}
        </S.CollapseContent>
      </AnimateHeight>
    </S.CollapseArea>
  );
};

export default Collapse;
