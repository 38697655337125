import RarityPDF from "assets/docs/rarity.pdf";

const Rarity = ({ isMobile }) => {
  return (
    <>
      {!isMobile ? (
        <embed
          src={RarityPDF}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ position: "absolute", top: 0, left: 0, height: "100vh" }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <a
            href={RarityPDF}
            download={"rarity"}
            style={{
              fontSize: "20px",
              fontWeight: 600,
              padding: "10px 30px",
              color: "#fff",
              backgroundColor: "#6D7DFF",
              borderRadius: "10px",
            }}
          >
            Download Rarity
          </a>
        </div>
      )}
    </>
  );
};

export default Rarity;
