import styled from "styled-components";

import FolkBackground from "assets/images/folk-background.png";
import { breakpoints } from "theme";

export const Section = styled.section`
  position: relative;
  text-align: center;
  margin-top: 85px;
  width: 100%;
  z-index: 2;
  /* padding: 0 25px; */

  /* &::after {
    position: relative;
    content: "";
    display: block;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 62.66%, #0b071f 100%),
      url(${FolkBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    width: 100%;
    height: 300px;
    margin-top: 100px;
    z-index: 2;
  }

  @media (max-width: 1100px) {
    height: auto;
    &::after {
      display: none;
    }
  } */
`;

export const ItemArea = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, 45%);
  /* padding: 0 60px 0 30px; */
  gap: 25px;
  row-gap: 35px;
  margin-top: 55px;
  /* gap: 70px; */
  /* margin: auto; */
  /* margin-top: 55px; */
  /* justify-items: center; */

  /* max-width: 1400px; */

  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(1, 100%);
    row-gap: 15px;
  }
`;

export const Item = styled.div``;
