import Image1 from "assets/images/item1.png";
import Image2 from "assets/images/item2.png";
import Image3 from "assets/images/item3.png";
import Image4 from "assets/images/item4.png";

// import DiscoverBackground from "assets/images/discovery-background.png";
// import Discover from "assets/images/discover.png";

// import KittiezBackground from "assets/images/kittiez-background.png";
// import WarrisolsBackground from "assets/images/warrisols-background.png";
// import RoyalderBackground from "assets/images/royalder-background.png";

import FolkItemBackground from "assets/images/folk-background.png";
// import FolkDeactiveBackground from "assets/images/folk-deactive-background.png";
import Bunniez from "assets/images/bunniez.png";
import UnknownIcon from "assets/icons/unknown.svg";

/* Sneak Peak  */
import SneakPeak1 from "assets/images/sneakpeak1.png";
import SneakPeak2 from "assets/images/sneakpeak2.png";
import SneakPeak3 from "assets/images/sneakpeak3.png";
import SneakPeak4 from "assets/images/sneakpeak4.png";
import SneakPeak5 from "assets/images/sneakpeak5.png";
import SneakPeak6 from "assets/images/sneakpeak6.jpg";
import SneakPeak7 from "assets/images/sneakpeak7.jpg";
import SneakPeak8 from "assets/images/sneakpeak8.jpg";
import SneakPeak9 from "assets/images/sneakpeak9.jpg";
import SneakPeak10 from "assets/images/sneakpeak10.jpg";

export const mobileSize = 992;

/*
    ### Menu Configurations ###
*/
export const menu = [
  {
    title: "Home",
    href: "/",
    soon: undefined,
    route: true,
  },
  {
    title: "Story",
    href: "/story",
    soon: undefined,
    route: true,
  },
  {
    title: "Whitepaper",
    href: "/whitepaper",
    soon: undefined,
    route: true,
  },
  {
    title: "Rarity",
    href: "/rarity",
    soon: undefined,
    route: true,
  },
  {
    title: "Solderland Mines",
    href: "https://mines.solderland.com",
    soon: false,
    route: false,
  },
  // {
  //   title: "WhitePaper",
  //   href: "#",
  //   soon: true,
  //   route: false,
  // },
  {
    title: "Medium",
    href: "https://solderland.medium.com/",
    target: "_blank",
    soon: false,
    route: false,
  },
];

/*
    ### Folk Of Solderland Configurations ###
*/

export const features = [
  {
    title: "Solderland Mines",
    description:
      "You can earn Solana, Solderland Token ($SLDR), and Magic Carrot by staking Bunnies in these mines.",
  },
  {
    title: "SOLDERLAND MYSTERY BOXES",
    description:
      "You can earn Solderland mystery boxes by staking your Bunnies. These boxes will contain many mysteries.",
  },

  {
    title: "Exclusive NFT Raffles",
    description:
      "Solderland supports the solana ecosystem solderland stakers earn nfts and whitelist spots from other projects by performing their tasks on the map.",
  },

  {
    title: "ORACLE BUNNY",
    description:
      "Take a closer look at the Solderland map. Would you like to have a deep chat with the Oracle Bunny? He has all the information about Solderland and can give you information that will give you a big advantage. What is it? Is he talking about a new dimension hidden in Solderland?",
  },
  {
    title: "MYSTERIOUS MESSAGES",
    description:
      "The map contains a mysterious message with a big reward at the end. Hints can be anywhere. By the way, might the Oracle Bunnies know some of these tips?",
  },

  {
    title: "DISCOVERY ZONES",
    description:
      "Solderland is a largely unexplored planet. Mysterious areas are full of new opportunities, different missions,and lots of mystery. Clouds in closed areas will disperse over time, but it is possible to accelerate this time.Bunnies working in exploration zones will speed up the unlocking of mysterious parts of the map. Want more excitement? The exploration zone is waiting for you.",
  },
];

/*
    ### Folk Of Solderland Configurations ###
*/
export const folkOfSolderland = [
  {
    title: "Bunnies",
    background: FolkItemBackground,
    image: Bunniez,
    active: true,
    description:
      "6666 minted Bunnies are responsible for mining at the planet Solderland. With the Gamification Stake, Bunnies can be sent to mines. While they are mining, they grant their owners with SOL and in-game items for the Metaverse game.",
  },
  {
    title: "SolRobo",
    background: FolkItemBackground,
    image: UnknownIcon,
    active: false,
    description:
      "5555 SolRobo are responsible for the construction at the Solderland planet. With the Gamification Stake, they can build buildings on the Solderland map, which grant their owners Solderland Tokens and in-game items for the Metaverse game.",
  },
  {
    title: "Warrisolz",
    background: FolkItemBackground,
    image: UnknownIcon,
    active: false,
    description:
      "3333 Warrisolz are battlers race on the planet Solderland with their fighting traits. They are responsible for the safety of other races. While they are doing it, they grant their owners Solderland Tokens and in-game items for the Metaverse game.",
  },
  {
    title: "Royalder",
    background: FolkItemBackground,
    image: UnknownIcon,
    active: false,
    description:
      "999 Royalder are the ruling race of the Solderland planet. Each living creature on the planet pays taxes to them. In return, they rule the community and maintain order on the Solderland Metaverse planet.",
  },
];

/*
    ### Sneak Peak ###
*/

export const sneakPeaks = [
  SneakPeak1,
  SneakPeak2,
  SneakPeak3,
  SneakPeak4,
  SneakPeak5,
  SneakPeak6,
  SneakPeak7,
  SneakPeak8,
  SneakPeak9,
  SneakPeak10,
];

/*
    ### Bunnies For Meet Configurations ###
*/
export const bunniesForMeet = [
  {
    image: Image1,
  },
  {
    image: Image2,
  },
  {
    image: Image3,
  },
  {
    image: Image4,
  },
  {
    image: Image2,
  },
];

/*
    ### Faq Configurations ###
*/
export const faq = [
  {
    question: "Is Solderland a game?",
    answer: "Yes, Solderland is First Gamified Staking Game in Solana",
  },
  {
    question: "Is gamification stake a game?",
    answer:
      "No, gamification stake is a staking method we developed for our community to act together until the metaverse game comes out.",
  },
  {
    question: "What will staking gain me?",
    answer:
      "By staking bunnies you will earn Solana, Solderland Token, Magic Carrot, NFTs, Whitelist Spots",
  },
  {
    question: "What is Magic Carrot?",
    answer: "In game-item",
  },
  {
    question: "Are 3D Bunnies paid?",
    answer:
      "If you are a verified holder you can pay 1000 Solderland Token and 10 Magic Carrot and get your 3D Bunny",
  },
  {
    question: "Will Solderland have another NFT collection?",
    answer:
      "4 different races live on Solderland. The first race is the Bunnies. Except for Bunnies, SolRobo, Warrisolz, and Royalder will be active in the game in the next period.",
  },
  {
    question: "When launch?",
    answer: "Presale & Public Sale: 25 January 8 PM UTC",
  },
  {
    question: "How much SOL is needed to mint one Bunny?",
    answer: "1 SOL",
  },
  {
    question: "What is the total supply?",
    answer: "6666 Bunnies live on Solderland",
  },
  {
    question: "How much NFT can be minted for 1 transaction?",
    answer: "2 NFT in Presale , 1 NFT per transaction in Public Sale.",
  },
  {
    question: "Which marketplaces Solderland Bunnies will be listed on?",
    answer: "Magiceden",
  },
  {
    question: "What will happen with Royalty Fee?",
    answer:
      "100% Royalty Fee will count towards NFT Stake rewards. In addition, 10% of sales revenue will be included in the NFT Stake awards.",
  },
  {
    question: "What makes Solderland Bunnies special?",
    answer:
      "Solderland Bunnies is the key to the Solderland Universe. To play Solderland, every user must have Bunnies. This will protect the Bunnies' value against inflation in the following period. Because Bunnie's owners are the first supporters of our project, we will make our supporters happy with airdrops and giveaways in every period exclusively for them.",
  },
  // {
  //   question: "What is the total supply?",
  //   answer:
  //     "Total supply: 6666 Solderland Bunnies.\nPresale supply: 100 Solderland Bunnies.\nPublic sale supply: 8763 Solderland Bunnies.\n25 Bunnies will be used marketing purposes and giveaways.",
  // },
  // {
  //   question: "How much NFT can be minted for 1 transaction?",
  //   answer: "1 NFT can be minted per transaction.",
  // },

  // {
  //   question: "Which marketplaces Solderland Bunnies will be listed on?",
  //   answer: "We are in contact with secondary exchanges.",
  // },
  // {
  //   question: "What is the royalty fee and where is it to be used?",
  //   answer:
  //     "80% will be used to send prizes to the holders.\n20% will be used for Sweeping & Marketing.\nSelling under the mint price will penalized 30% Royalty fee and it will be used for floor sweeping.",
  // },
  // {
  //   question: "What makes Solderland Bunnies unique?",
  //   answer: "TBA.",
  // },
];

/*
  ### Roadmap ###
*/

export const roadmap = [
  {
    date: "December 2021",
    toDoList: [
      "Growing the community and better explaining the project to the community",
      "Solderland mines demo launch",
      "Medium page release",
      "Introducing the demo project with influencers on Youtube",
      "Introducing demo project on Reddit",
      "Demo project promotion on Twitter",
      "Announcement of the prize pool of the demo project",
      "Disclosure of surprises on the demo project",
    ],
    active: true,
  },
  {
    date: "January 2022",
    toDoList: [
      "Marketing and Promotions",
      "Magiceden Launch ",
      "Public Sale",
      "Presale",
      "Demo Game Solana Reward Distribution",
      "Team AMA after Public Sale",
    ],
    active: true,
  },
  {
    date: "February 2022",
    toDoList: [
      "Solderland Token and Magic Carrot Distributions to Demo Game Players",
      "Community Giveaways",
      "Blockchain Cybersecurity Development of Community Reward Wallet",
      "Blockchain Cybersecurity Development of Solderland Mines",
      "Blockchain Cybersecurity Verification of Solderland Mines",
      "Holder Verification",
      "Solderland Mines Production Version Test with 100 Selected Verified Holder",
      "Solderland Token and Magic Carrot Distribution to Demo Game Players",
      "Solderland Mines Production Version Launch",
      "Collobrations for Solderland Mines with Famous projects in Solana",
      "Collobrations for Solderland Mines with Promising projects in Solana",
      "Collobrations for Solderland Mines with Real World Companies",
      "Marketing and Promotions",
    ],
    active: true,
  },
  {
    date: "March 2022",
    toDoList: [
      "Solderland Token Presale",
      "Introducing Land Sale from Solderland",
      "Introducing Building Sale From Solderland",
      "Introducing Solderland Mines marketplace",
      "Launch v.2 Solderland Mines Game (Marketplace and Blockchain Cybersecurity Developments)",
      "Marketing and Promotions",
      "Team AMA",
    ],
    active: true,
  },
  {
    date: "Q2 2022",
    toDoList: [
      "3D Bunny Claims",
      "Introducing Clan System",
      "Introducing New Mission Types in Solderland Mines",
      "Introducing Co-Op Missions",
      "Solderland New Dimension : Metaverse Teaser",
      "Marketing and Promotions for Metaverse Game",
    ],
    active: false,
  },
  {
    date: "Q3 2022",
    toDoList: [
      "Introducing Sol Robo NFT Collection",
      "Introducing Sol Robo NFT Collection’s Responsibilities on Solderland Mines",
      "Introducing Sol Robo’s Construction Abilities",
      "Introducing Sol Robo’s Buildings (Clanbases and etc.)",
      "Sol Robo Special sale for Bunny holders",
      "Marketing and Promotions",
    ],
    active: false,
  },
  {
    date: "Q4 2022",
    toDoList: [
      "Metaverse Game Test Applications",
      "Solderland Mines v.3 Launch (Sol Robo)",
      "Sol Robo Buildings launch on marketplace",
      "Introducing Urbanization in Solderland Mines",
      "Introducing loot and attack mechanics",
      "Introducing loot storage and protection",
      "Introducing Warrisolz NFT Collection",
      "Introducing Warrisolz NFT Collection’s Responsibilities on Solderland Mines",
      "Introducing Warrisolz’s Military Abilities",
      "Introducing Warrisolz Items (watchtowers, Military Base, Weapons and etc.)",
      "Warrisolz Special sale for Bunny and Sol Robo holders",
      "Solderland Mines v.04 Launch (Warrisolz)",
    ],
    active: false,
  },
];
