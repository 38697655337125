import React from "react";

/* Import Component */
import Welcome from "components/sections/Welcome";
import WhatIsSolderland from "components/sections/WhatIsSolderland";
import Folk from "components/sections/Folk";
import SneakPeak from "components/sections/SneakPeak";
// import Meet from "components/sections/Meet/";
import Roadmap from "components/sections/Roadmap";
import Features from "components/sections/Features";
import RewardBanner from "components/sections/RewardBanner";
import Faq from "components/sections/Faq";
import Footer from "components/sections/Footer";

const Home = (props) => {
  return (
    <main id="home">
      <Welcome {...props} />
      {/* <SneakPeak {...props} /> */}
      <WhatIsSolderland />
      <Features {...props} />
      {/* <RewardBanner /> */}
      <Folk />
      {/* <Meet /> */}
      <Roadmap {...props} />
      <Faq />
      <Footer />
    </main>
  );
};

export default Home;
