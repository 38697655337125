import { useState } from "react";
import * as S from "../styles/Beta.styles";

import axios from "axios";

import { ReactComponent as LeftArrowIcon } from "assets/icons/left-arrow.svg";
import { ReactComponent as DiscordIcon } from "assets/icons/discord-white.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/twitter-white.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram-white.svg";
import { ReactComponent as MediumIcon } from "assets/icons/medium-white.svg";
import { Link } from "react-router-dom";

enum StepType {
  Form = "FORM",
  FormResult = "FORM_RESULT",
}

const baseURL = process.env.REACT_APP_BACKEND_PATH;

const Form = ({ changePage }) => {
  const [discordUsername, setDiscord] = useState("");
  const [referenceUsername, setReference] = useState("");
  const [twitterUsername, setTwitter] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  async function apply() {
    setErrorMessage("");

    if (!discordUsername)
      return setErrorMessage("Discord username is required");

    if (!/^.{3,32}#[0-9]{4}$/.exec(discordUsername))
      return setErrorMessage("Please enter a valid discord username");

    if (referenceUsername && !/^.{3,32}#[0-9]{4}$/.exec(referenceUsername))
      return setErrorMessage("Please enter a valid discord reference username");

    try {
      await axios.put(`${baseURL}/auth/beta-user`, {
        discordUsername,
        referenceUsername,
        twitterUsername,
      });
      changePage(StepType.FormResult);
    } catch (error) {
      if (axios.isAxiosError(error))
        setErrorMessage(error.response?.data?.message);
    }
  }

  return (
    <S.FormSection id="register-beta">
      <S.FormHeader>
        <Link to="/">
          <S.BackHome>
            <LeftArrowIcon />
            <span>Back to Home</span>
          </S.BackHome>
        </Link>
        <S.FormHeaderSocialMedia>
          <a
            href="https://twitter.com/solderland"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://discord.com/invite/solderland"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
          <a
            href="https://instagram.com/solderland_nft"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://solderland.medium.com/"
            target="_blank"
            rel="noreferrer"
          >
            <MediumIcon />
          </a>
        </S.FormHeaderSocialMedia>
      </S.FormHeader>

      <S.FormContent>
        <S.FormContentTitle>
          $10,000 worth of Solana in Solderland Mines!
        </S.FormContentTitle>
        <S.FormContentDescription>
          Welcome to Solderland. In the demo version, $10,000 worth of Solana is
          waiting to be mined in Solderland mines. Will you be lucky enough to
          discover Solderland early?
        </S.FormContentDescription>
        <S.FormArea>
          <S.FormItem>
            <label htmlFor="discordUsername">Your Discord Username</label>
            <S.FormItemInput>
              <input
                type="text"
                id="discordUsername"
                placeholder="Solderland#2354"
                onChange={(e) => setDiscord(e.target.value)}
              />
              <span>Mandatory</span>
            </S.FormItemInput>
            <small>
              *You must have joined the Solderland Discord server. If you
              haven't joined, you can join by{" "}
              <a href="https://discord.gg/solderland">clicking here</a>.
            </small>
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="referenceUsername">
              Reference Discord Username
            </label>
            <S.FormItemInput>
              <input
                type="text"
                id="referenceUsername"
                placeholder="Rubensz#9542"
                onChange={(e) => setReference(e.target.value)}
              />
              <span className="optional">Optional</span>
            </S.FormItemInput>
            <small>
              *Share Discord ID of your friend who recommended Solderland to
              you. We have surprises for both of you.
            </small>
          </S.FormItem>
          <S.FormItem>
            <label htmlFor="twitterUsername">Twitter account</label>
            <S.FormItemInput>
              <input
                type="text"
                id="twitterUsername"
                placeholder="@username"
                onChange={(e) => setTwitter(e.target.value)}
              />
              <span className="optional">Optional</span>
            </S.FormItemInput>
          </S.FormItem>
          <S.FormItem>
            {errorMessage ? (
              <S.ErrorMessage>{errorMessage}</S.ErrorMessage>
            ) : (
              ""
            )}
            <S.ApplyButton onClick={() => apply()}>Apply</S.ApplyButton>
          </S.FormItem>
          <S.Footer>
            <S.FooterItem
              href="https://solderland.medium.com/solderland-mines-102454a181b5"
              target="_blank"
              rel="noreferrer"
            >
              About Solderland Mines
            </S.FooterItem>
            <S.FooterItem
              href="https://solderland.medium.com/gamification-stake-model-solderland-mines-18d490d36b21"
              target="_blank"
              rel="noreferrer"
            >
              Rewards
            </S.FooterItem>
            <S.FooterItem
              href="https://solderland.medium.com/solderland-mines-102454a181b5"
              target="_blank"
              rel="noreferrer"
            >
              How to Play?
            </S.FooterItem>
          </S.Footer>
        </S.FormArea>
      </S.FormContent>
    </S.FormSection>
  );
};

const FormResult = () => {
  function redirectUrl() {
    window.location.href =
      "https://solderland.medium.com/solderland-mines-102454a181b5";
  }

  return (
    <S.FormResultSection id="form-result">
      <S.FormResultWrapper>
        <S.FormResultTitle>
          Your application has been received
        </S.FormResultTitle>
        <S.FormResultDescription>
          You will be able to access Solderland Mines once applications are
          closed. Don't forget to follow our Discord server and our twitter
          address for announcement.
        </S.FormResultDescription>
        <S.FormResultSocialMedia>
          <a
            href="https://twitter.com/solderland"
            target="_blank"
            rel="noreferrer"
          >
            <TwitterIcon />
          </a>
          <a
            href="https://discord.com/invite/solderland"
            target="_blank"
            rel="noreferrer"
          >
            <DiscordIcon />
          </a>
          <a
            href="https://instagram.com/solderland_nft"
            target="_blank"
            rel="noreferrer"
          >
            <InstagramIcon />
          </a>
          <a
            href="https://solderland.medium.com/"
            target="_blank"
            rel="noreferrer"
          >
            <MediumIcon />
          </a>
        </S.FormResultSocialMedia>
        <S.FormResultDiscoverMode onClick={() => redirectUrl()}>
          Discover Mode
        </S.FormResultDiscoverMode>
        <Link to="/">
          <S.FormResultBackHome>
            <LeftArrowIcon />
            <span>Back to Home</span>
          </S.FormResultBackHome>
        </Link>
      </S.FormResultWrapper>
    </S.FormResultSection>
  );
};

const Beta = () => {
  const [step, setStep] = useState(StepType.Form);

  return step === StepType.Form ? (
    <Form changePage={(type) => setStep(type)} />
  ) : (
    <FormResult />
  );
};

export default Beta;
