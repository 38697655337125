import styled, { css } from "styled-components";

import { Button } from "styles/Global.styles";

import { ReactComponent as SocialMediaHeaderIcon } from "assets/images/Discovery.svg";
import { ReactComponent as DiscordIcon } from "assets/icons/Discord.svg";
import { ReactComponent as TwitterIcon } from "assets/icons/Twitter.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/Instagram.svg";

export const SocialMedia = styled.div<{ isMobile: boolean }>`
  position: ${(props) => (props.isMobile ? "relative" : "absolute")};
  z-index: 6;
  ${(props) =>
    !props.isMobile &&
    css`
      right: 30px;
      top: 175px;
    `};
`;

export const SocialMediaWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isMobile ? "row" : "column")};
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isMobile &&
    css`
      margin-top: 30px;
      gap: 30px;
      /* -webkit-animation: none; */
    `}
`;

export const SocialMediaButton = styled(Button)<{ isMobile: boolean }>`
  margin-top: 35px;
  height: 75px;
  width: 75px;
  padding: 17px 17px 23px 23px;

  ${(props) =>
    !props.isMobile &&
    css`
      &:after {
        right: -8px;
        top: -8px;
      }

      &:not(:nth-child(2)) {
        &:before {
          height: 36px;
          top: -44px;
        }
      }

      &:before {
        position: absolute;
        content: "";
        height: 66px;
        width: 2px;
        background-color: #4bb1ff;

        top: -73px;
        left: calc(100% - 50% + 4px);
      }
    `}
`;

export const DiscordButton = styled(DiscordIcon)`
  /* width: 32px;
  height: 24px; */
`;

export const TwitterButton = styled(TwitterIcon)`
  /* width: 32px;
  height: 24px; */
`;

export const InstagramButton = styled(InstagramIcon)`
  /* width: 32px;
  height: 24px; */
`;

export const SocialMediaHeader = styled(SocialMediaHeaderIcon)`
  margin-left: 10px;
`;
