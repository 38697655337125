import styled from "styled-components";
import { Description } from "styles/Global.styles";
import Correct from "assets/images/correct.png";
import { breakpoints } from "theme";

export const Section = styled.section`
  position: relative;
  text-align: center;
  margin-top: 85px;
  width: 100%;
  z-index: 2;
`;

export const RoadmapArea = styled.div`
  max-width: 1027px;
  margin: 10px auto 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 15px 0px;

  &::after {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 60px);
    top: 60px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%232A1F5DFF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  @media ${breakpoints.mobile} {
    padding: 0 20px;
    &::after {
      display: none;
    }
  }
`;

export const RoadmapItem = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  border-radius: 10px;
  position: relative;
  width: 485px;
  opacity: ${(props) => (props.active ? "1" : "0.5")};

  margin-top: 50px;

  border: 1px solid
    ${(props) => (props.active ? "#f89a34" : "rgba(228, 61, 227, 0.3)")};
  border-radius: 10px;

  padding: 20px 57px 29px 24px;

  /* carrot */
  &::before {
    content: "";
    position: absolute;
    top: 50%;
    width: 100px;
    height: 130px;
    transform: translateY(-50%);
    background-image: url(${Correct});
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 3;
    opacity: ${(props) => (props.active ? "1" : "0.3")};
  }

  /* secondary line */
  &::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    border: 1px solid
      ${(props) => (props.active ? "#617d3a" : "rgba(14, 219, 255, 0.3)")};
    border-radius: 10px;
    left: -10px;
    top: -10px;
  }

  &:nth-child(even) {
    margin-left: auto;
    left: 53px;

    &::before {
      left: -122px;
    }
  }

  &:nth-child(odd) {
    right: 50px;

    &::before {
      right: -141px;
    }
  }

  @media ${breakpoints.mobile} {
    width: 100%;
    left: 0px !important;
    right: 0px !important;

    height: auto;

    &:not(:first-child) {
      margin-top: 100px;
    }

    &:not(:last-child) {
      &::before {
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23F89A34FF' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        transform: translateX(-50%);
        top: 100%;
        width: 2px;
        margin-top: 2px;
        /* margin-top: 30px; */
        height: 90px;
        /* left: 0 !important; */
        /* right: 0 !important; */
        opacity: 1;
        /* left: 20px; */
      }

      &:nth-child(odd) {
        &::before {
          margin-left: auto;
          right: 30px;
          /* left: 0; */
        }
      }

      &:nth-child(even) {
        &::before {
          margin-right: auto;
          /* right: 20px; */
          left: 30px;
        }
      }
    }

    &:last-child {
      &:before {
        display: none;
      }
    }
  }
`;

export const RoadmapTitle = styled(Description)`
  font-size: 16px;
  color: #f7f7f7;
  text-transform: uppercase;
  z-index: 3;
  text-align: center;
`;

export const RoadmapDescription = styled(Description)`
  font-weight: normal;
  font-size: 17px;
  line-height: 27px;
  letter-spacing: 0.7px;
  color: #f7f7f7;
  z-index: 3;
  width: 100%;
`;

export const Buttons = styled.div`
  background: #080417;
  border: 1px solid #1b154f;
  margin: auto;
  border-radius: 10px;
  width: fit-content;
  margin-top: 10px;
`;

export const Button = styled.button<{ active: boolean }>`
  font-weight: bold;
  font-size: 16px;
  padding: 20px 30px;
  text-transform: uppercase;

  border-radius: 10px;
  color: ${(props) => (props.active ? "#080417;" : "#D850FE;")};
  background-color: ${(props) => (props.active ? " #D850FE;" : "#080417;")};

  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
`;
