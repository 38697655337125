import Container from "components/Container";
import Header from "components/sections/Header";
import {
  Title,
  WriteContent,
  WriteContentDescription,
  WriteContentImage,
} from "styles/Global.styles";

import MinesImage from "assets/images/writes/mines.jpg";
import GamekeyImage from "assets/images/writes/game-key.jpg";
import PowerImage from "assets/images/writes/power.jpg";

const Stake = (props) => {
  return (
    <section className="stake">
      <Header {...props} />
      <Container>
        <WriteContent>
          <Title>Gamification Stake Model: Solderland Mines</Title>
          <WriteContentDescription>
            Solderland map is a gamification staking project, which is a
            combination of the NFT Stake feature and web-based game systems and
            is the key to the Solderland Metaverse universe. Our users who want
            to participate in the Solderland Metaverse game must perform NFT
            Stake on the Solderland map and complete the tasks on the map. NFT
            Holders who complete the missions on the map will receive the
            following rewards;
          </WriteContentDescription>
          <WriteContentImage src={MinesImage}></WriteContentImage>
          <WriteContentDescription>
            Solana pool in the prize pool will be created with a 100% royalty
            fee and 10% of our sales revenue. In addition, those who complete
            the missions on the map will earn 1000 Solderland Tokens and 10
            Magic Carrot For 10 Magic Carrots and 1000 Solderland tokens, you
            will receive a key to the Solderland Metaverse project. This key
            turns your NFT into an in-game 3D character.
          </WriteContentDescription>
          <WriteContentImage src={GamekeyImage}></WriteContentImage>
          <WriteContentDescription>
            Solderland map contains many mysterious mission Each mission
            provides rewards to NFT Holders and can have hints for the next
            mission. In addition, the top 10 Tokens in the Solana ecosystem are
            hidden at certain points of the map. NFT Holders who find these
            tokens are entitled to receive earnings in proportion to their
            weight in the pool.
          </WriteContentDescription>
          <WriteContentDescription bold>
            So how is APY calculated on the Solderland map?
          </WriteContentDescription>
          <WriteContentDescription>
            The indicator we named Solderland Power represents your weight in
            the pool after staking your NFT The higher this ratio, the more you
            will earn from the prizes specified in the pool.
          </WriteContentDescription>
          <WriteContentImage src={PowerImage}></WriteContentImage>
        </WriteContent>
      </Container>
    </section>
  );
};

export default Stake;
