import * as S from "./styles";
import { Title } from "styles/Global.styles";
import Container from "components/Container";

import Header from "../Header";
import SocialMedia from "components/SocialMedia";
// import { Link } from "react-router-dom";

const Welcome = ({ isMobile }) => {
  return (
    <S.Section id="staging" isMobile={isMobile}>
      <Header isMobile={isMobile} />
      <Container>
        <S.SectionWrapper>
          <S.Text>
            <S.TitleWrapper>
              <Title>First Gamified NFT Staking Project in Solana</Title>
            </S.TitleWrapper>
            <S.DescriptionClass>
              Are you ready to explore this unique planet, dazzling with its
              precious metals and undiscovered lands? <br />
              You must have at least one Solderland Bunnies with you to embark
              on this journey. <br />
              Working in the mines, Solderland Bunnies will bring you valuable
              loot and become keys to many regions. <br />
              Are you ready for a new experience?
            </S.DescriptionClass>
            <a href="https://mines.solderland.com">
              <S.ButtonStyle>
                <span>Play Solderland Mines</span>
              </S.ButtonStyle>
            </a>
          </S.Text>

          <SocialMedia isMobile={isMobile}></SocialMedia>
        </S.SectionWrapper>
      </Container>
    </S.Section>
  );
};

export default Welcome;
