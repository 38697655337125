import Container from "components/Container";
import Header from "components/sections/Header";
import {
  Title,
  WriteContent,
  WriteContentDescription,
} from "styles/Global.styles";

const Story = (props) => {
  return (
    <section className="stake">
      <Header {...props} />
      <Container>
        <WriteContent>
          <Title>Story of Solderland</Title>
          <WriteContentDescription>
            Are you ready to explore a unique planet located in the Solana
            Blockchain Galaxy? Humanity must make discoveries due to the
            scarcity and resource problems in the world. On this journey of
            discovery, they come across a planet that they do not know of yet
            and that they have never come across before. They do not have enough
            knowledge and equipment to explore this planet. Therefore, it will
            take some time to explore this mysterious planet. Let’s talk about
            Solderland as it was discovered as a result of the first researches.
            It is a very valuable planet with lands that have never been taken
            yet, dark areas, an active life, and most importantly, SOL mines.
            Four different races were observed living in the discoverable areas.
            All races seem to have a mission on this planet. The most known race
            is Solderland Bunnies. They are almost everywhere. Living in huge
            underground cities, these bunnies are very skilled at digging mines.
            They are also the only race that can leave the planet and re-enter.
            What are these precious metals used for on this mysterious planet?
            Could the energy of this mysterious planet be these mines? This
            issue remains a mystery. So, can these mines save the future of
            humanity? To get the answers to these questions, humanity is trying
            to find a way of communication with the Solderland Bunnies. Will the
            Solderland Bunnies help these intruders?
          </WriteContentDescription>
          <WriteContentDescription>
            The expedition returns to the world with great news. Bunnies agreed
            with the expedition that they would do anything in their power to
            help humanity. A large team will complete their preparations and set
            off for Solderland. Join the journey of discovering Solderland.
            Thanks to your Solderland Bunnies, you will now be able to gather
            much more information about this mysterious planet. You can work
            with as many bunnies as you want while exploring Solderland. Every
            part of this planet is a clue. Follow all the clues to find the most
            productive places. Your Bunnies will provide you with resources by
            working in the places you designate and completing quests in those
            areas. These resources will initially be given as Solana. According
            to the discovery team, the token used by the Solderland people among
            themselves is also waiting to be discovered. When you complete
            quests and reach the Solderland Token area, you can now mine
            Solderland Tokens. Also, according to the information received from
            the wandering Bunnies, they may return with some valuable loot from
            their trip to Solana Blockchain. These loots are hidden on the map.
            10 very valuable NFTs are hidden in the far corners of the map. Will
            you be able to crack the passwords and own them?
          </WriteContentDescription>
          <WriteContentDescription>
            What is this? Naul from the expedition says he has information that
            will get everyone excited. Naul reports that Solderland Bunnies can
            find Magic Carrots, which are very rare to find. These Magic Carrots
            are the key to a new dimension and allow Bunnies to transform. He
            says 1000 Solderland Tokens and 10 Magic carrots will be enough for
            this. Are you ready to gather the necessary resources and move on to
            a new dimension?
          </WriteContentDescription>
          <WriteContentDescription>
            A detailed guide describing the speed and rate of digging during the
            expedition has also been distributed to everyone. Check it out now!
          </WriteContentDescription>
        </WriteContent>
      </Container>
    </section>
  );
};

export default Story;
