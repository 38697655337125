import * as S from "./styles";
import Container from "components/Container";
import { Description } from "styles/Global.styles";

const Footer = () => {
  return (
    <S.Section>
      <Container>
        <Description bold>
          Need more answers? Join our Discord channel now.{" "}
        </Description>
        <S.DiscordJoinButton href="https://discord.com/invite/solderland">
          <S.DiscordIconStyle />
          <S.DiscordTextButton>Join Solderland</S.DiscordTextButton>
        </S.DiscordJoinButton>
      </Container>
    </S.Section>
  );
};

export default Footer;
