import styled from "styled-components";

import { breakpoints } from "theme";
import { ReactComponent as SolanaIcon } from "assets/icons/solana.svg";

import information from "assets/icons/information.png";
import MineImage from "assets/images/mine.png";

export const Section = styled.section`
  position: relative;
  display: block;
  width: 100%;
  text-align: center;

  margin: 0 auto 0 auto;

  min-height: 100vh;

  @media ${breakpoints.mobile} {
    padding: 0px 25px 50px 25px;
  }
`;

export const MinesIcon = styled.img`
  position: relative;
  margin: 80px 0 45px 0;
  z-index: 3;
`;

export const MapPicture = styled.picture`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  opacity: 0.3;

  img {
    width: 100%;
    height: 100%;
    @media ${breakpoints.mobile} {
      object-fit: cover;
      object-position: 30%;
    }
  }

  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #0b071f 6.12%, rgba(11, 7, 31, 0) 50%),
      linear-gradient(180deg, rgba(11, 7, 31, 0.45) 41.51%, #0b071f 95.87%);
  }
`;

export const ExploreButton = styled.a`
  position: relative;
  z-index: 3;
  display: inline-block;
  margin-top: 40px;
  padding: 15px 40px;
  text-transform: uppercase;
  border: 1px solid rgba(228, 61, 227, 0.4);
  border-radius: 5px;
  cursor: pointer;
  z-index: 3;

  span {
    background: linear-gradient(
      270deg,
      #e54de5 0%,
      rgba(185, 255, 0, 0.78) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    font-size: 25px;
  }
  @media ${breakpoints.mobile} {
    span {
      font-size: 20px;
    }

    padding: 15px 20px;
  }
`;

export const PromotionArea = styled.div`
  position: relative;
  z-index: 3;
  background: rgba(0, 0, 0, 0.65);
  border: 5px solid rgba(98, 99, 186, 0.2);
  border-radius: 6px;
  padding: 30px;
  max-width: 630px;
  margin: auto;
  margin-top: 40px;

  &:after {
    position: absolute;
    content: "";

    background-image: url(${MineImage});
    right: -80px;
    bottom: -60px;
    height: 120px;
    width: 160px;
    background-repeat: no-repeat;

    @media ${breakpoints.mobile} {
      right: -45px;
    }
  }
`;

export const PromotionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media ${breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const PromotionHeaderText = styled.h6`
  font-style: normal;
  font-weight: 900;
  font-size: 19px;
  color: #ffffff;
`;

export const PromotionHeaderWalletArea = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  background: rgba(98, 99, 186, 0.4);
  border: 1px solid rgba(98, 99, 186, 0.4);
  border-radius: 4px;
  margin-top: 25px;
`;
export const PromotionHeaderWalletIcon = styled(SolanaIcon)`
  opacity: 0.8;
`;
export const PromotionHeaderWalletIdText = styled.span`
  font-weight: normal;
  font-size: 12px;
  color: #ffffff;
`;

export const PromotionCardArea = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 20%);
  justify-items: center;

  /* gap: 20px; */
  margin-top: 25px;

  @media ${breakpoints.mobile} {
    display: grid;
    grid-template-columns: repeat(3, 32%);
  }
  @media (max-width: 767px) {
    display: grid;
    grid-template-columns: repeat(2, 50%);
  }
`;

export const PromotionCard = styled.div`
  position: relative;
  width: 100px;
  height: fit-content;
  &:last-child {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    text-align: left;
    border: 5px solid rgba(142, 142, 142, 0.28);
    border-radius: 5px;
    padding: 10px 5px;
  }
  &:not(:last-child) {
    &:after {
      position: absolute;
      content: "";
      background: url(${information});
      width: 20px;
      height: 20px;
      top: -10px;
      right: -10px;

      @media ${breakpoints.mobile} {
        top: 0;
        right: 0;
      }
    }
  }
`;

export const PromotionCardImage = styled.img<{ assign?: boolean }>`
  border: 5px solid
    ${(props) =>
      props.assign ? "rgba(35, 186, 86, 0.7)" : "rgba(142, 142, 142, 0.28)"};
  border-radius: 5px;
  height: auto;
  width: 100%;
`;

export const PromotionCardText = styled.span`
  font-weight: normal;
  font-size: 11px;
  color: #ffffff;
`;

export const PromotionIcons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 8px;
`;

// export const PromotionIcon1 = styled(CardLogo1)``;

export const PromotionCardButton = styled.button<{ assign?: boolean }>`
  position: relative;
  background: ${(props) =>
    props.assign
      ? "linear-gradient(0deg, #23BA56, #23BA56), #C4C4C4"
      : " linear-gradient(270deg, #505fb8 0%, #8e4dca 51.56%, #23ba9d 100%),#c4c4c4;"};

  /* border: 5px solid rgba(255, 255, 255, 0.17); */
  border-radius: 5px;

  font-weight: 900;
  font-size: 10px;
  color: #ffffff;

  padding: 4px 8px;
  top: -20px;

  &:after {
    position: absolute;
    content: "";
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: rgba(255, 255, 255, 0.17);
    border-radius: 5px;
  }
`;

export const PromotionContentLeft = styled.div`
  text-align: center;
  width: 100%;
  /* @media ${breakpoints.mobile} {
    width: 100%;
    text-align: center;
  } */
`;

export const PromotionContentHeaderText = styled.h6`
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  color: #23ba56;
`;
export const PromotionContentDescriptionText = styled.p`
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  color: #ffffff;
  margin-top: 3px;
`;

export const PromotionContentInputCheckboxArea = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
`;
export const PromotionContentInputCheckbox = styled.input`
  background: #333333;
  border-radius: 1px;
  height: 15px;
  width: 15px;
  &:checked {
    background: linear-gradient(180deg, #23ba56 0%, #136c31 100%);
    border-radius: 1px;
    height: 10px;
    width: 10px;
  }
`;
export const PromotinoContentInputCheckoutboxText = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 8px;
  color: #ffffff;

  span {
    font-weight: 500;
    color: #0edbff;
  }
`;

export const PromotionContentButton = styled.button`
  position: relative;
  background: linear-gradient(0deg, #23ba56, #23ba56), #c4c4c4;
  border-radius: 3px;

  margin-top: 10px;
  padding: 10px 40px;
  text-transform: uppercase;

  color: #fff;
  font-size: 10px;

  &:after {
    position: absolute;
    content: "";
    top: -2px;
    left: -2px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    background: rgba(255, 255, 255, 0.17);
    border-radius: 3px;
  }
`;

export const PromotionContentRight = styled.div`
  width: 45%;
  text-align: left;

  &:before {
    position: absolute;
    content: "";
    left: 47.5%;

    width: 2px;
    background: linear-gradient(
      270deg,
      rgba(62, 62, 62, 0) 0%,
      rgba(62, 62, 62, 0.6) 44.35%,
      rgba(62, 62, 62, 0) 92.55%
    );
    height: 95%;
    top: 2.5%;
  }

  @media ${breakpoints.mobile} {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    &:before {
      display: none;
    }
  }
`;

export const PromotionContentRightItem = styled.div`
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const PromotionContentRightItemLabel = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 11px;

  color: #ffffff;
`;

export const PromotionContentRightItemProcessBar = styled.div<{
  percent: number;
  color: string;
}>`
  position: relative;

  display: flex;
  align-items: center;
  padding-left: 10px;

  margin-top: 7px;
  background: rgba(198, 227, 255, 0.4);
  border-radius: 1px;
  height: 20px;
  width: 100%;

  &:before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    background: ${(props) => props.color};
    width: ${(props) => props.percent}%;
    z-index: 1;
  }
`;

export const PromotionContentRightItemProcessBarText = styled.span`
  font-weight: 900;
  font-size: 10px;
  line-height: 14px;
  color: #ffffff;
  z-index: 2;
`;

export const PromotionFooter = styled.div`
  margin-top: 37px;

  display: flex;
  justify-content: space-between;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const PromotionFooterLeft = styled.div`
  width: 45%;
  text-align: left;

  @media ${breakpoints.mobile} {
    width: 100%;
    text-align: center;
  }
`;

export const PromotionFooterLeftText = styled.h6`
  font-style: normal;
  font-weight: 800;
  font-size: 12px;
  line-height: 16px;

  color: #ffffff;
`;

export const PromotionFooterRight = styled.div`
  text-align: right;
  @media ${breakpoints.mobile} {
    width: 100%;
    text-align: center;
  }
`;

export const PromotionFooterRightTime = styled.div`
  background: linear-gradient(
    180deg,
    rgba(85, 93, 186, 0.48) 0%,
    rgba(83, 94, 185, 0.3) 100%
  );
  border: 2px solid rgba(133, 148, 255, 0.38);
  border-radius: 3px;

  padding: 8px 18px;

  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  span {
    font-weight: bold;
  }
`;

export const DemoText = styled.span`
  font-weight: normal;
  display: block;
  font-size: 10px;
  color: #dd3f2f;
  margin-top: 40px;
  text-align: center;
`;

export const AssignButton = styled.button`
  background: linear-gradient(0deg, #23ba56, #23ba56), #c4c4c4;
  border: 2px solid rgba(255, 255, 255, 0.17);
  border-radius: 3px;
  padding: 10px;

  color: white;
  text-transform: uppercase;

  font-weight: 900;
  font-size: 15px;

  margin-top: 40px;

  width: 100%;
`;

export const WatchDemo = styled.p`
  position: relative;
  margin-top: 20px;
  font-weight: normal;
  font-size: 15px;
  color: #e868e9;
`;

export const ScrollDown = styled.div`
  border: 2px solid #fff;
  border-radius: 20px;
  height: 50px;
  margin: 0 auto;
  position: relative;
  top: 30px;
  width: 30px;

  &::before {
    animation: scrollDownAnimation 2s infinite;
    background-color: #fff;
    border-radius: 100%;
    content: "";
    height: 6px;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 10px;
    width: 6px;
  }
`;
