import * as S from "./styles";
import LoadingGif from "assets/icons/loading-min.gif";

const Loading = () => {
  return (
    <S.Section>
      <S.Loading src={LoadingGif} />
      <S.LoadingText>Loading...</S.LoadingText>
    </S.Section>
  );
};

export default Loading;
