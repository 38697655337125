import * as S from "./styles";

const FolkCard = ({ background, image, active, title, description }) => {
  return (
    <S.Card>
      <S.CardBackground active={active} background={background}>
        <S.CardImage src={image} active={active}></S.CardImage>
        <S.CardTextArea>
          <S.CardTitle>{title}</S.CardTitle>
          <S.CardDescription>{description}</S.CardDescription>
        </S.CardTextArea>
      </S.CardBackground>
    </S.Card>
  );
};

export default FolkCard;
