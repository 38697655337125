import styled, { css } from "styled-components";
import { breakpoints } from "theme";
import { Button } from "styles/Global.styles";

import CardBackground from "assets/images/feature-card-background.png";

export const Section = styled.section`
  position: relative;
  text-align: center;
  width: 100%;
  z-index: 2;

  margin-top: 100px;
`;

export const ItemArea = styled.div<{ mobile }>`
  margin-top: 55px;

  ${(props) =>
    !props.mobile &&
    css`
      display: grid;
      grid-template-columns: repeat(3, 30%);
      justify-content: center;
      padding: 0 20px;
      gap: 20px;
      @media ${breakpoints.mobile} {
        grid-template-columns: repeat(2, 50%);
      }

      @media (max-width: 500px) {
        grid-template-columns: repeat(1, 100%);
        height: 450px;
      }
    `}
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;

  padding: 100px 40px 100px 40px;
  background: #08041b;
  border: 5px solid rgba(255, 255, 255, 0.07);
  border-radius: 9px;
  cursor: pointer;

  /* background-image: url(${CardBackground});
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain; */

  @media ${breakpoints.mobile} {
    grid-template-columns: repeat(1, 100%);
    height: 450px;
  }
`;

export const ItemTitle = styled.h3`
  font-weight: bold;
  font-size: 28px;
  text-transform: uppercase;
  background: linear-gradient(270deg, #e54de5 0%, rgba(185, 255, 0, 0.78) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  margin: 0;

  @media ${breakpoints.mobile} {
    font-size: 30px;
  }
`;

export const ItemDescription = styled.span`
  color: white;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
`;

export const FindButton = styled(Button)`
  margin-top: 55px;
  color: white;
  text-transform: none;
  font-weight: 600;
  font-size: 25px;

  @media ${breakpoints.mobile} {
    font-weight: 600;
    font-size: 20px;
    padding: 10px 35px 20px 35px;
  }
`;
