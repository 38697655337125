import * as S from "./styles";
import { Description } from "styles/Global.styles";
import Container from "components/Container";

// import WalletImage1 from "assets/images/wallet-image1.jpg";
// import WalletImage2 from "assets/images/wallet-image2.png";
// import WalletImage3 from "assets/images/wallet-image3.png";
// import WalletImage4 from "assets/images/wallet-image4.png";

// import CardLogo1 from "assets/icons/logo1.png";
// import CardLogo2 from "assets/icons/logo2.png";
// import CardLogo3 from "assets/icons/logo3.png";

import MinesIcon from "assets/images/mines-icon.png";
// import MapBackground from "assets/images/home-background.jpg";

import Video from "assets/videos/mines.mp4";

const WhatIsSolderland = () => {
  return (
    <S.Section id="map">
      <S.MinesIcon src={MinesIcon} alt="" />
      <Container>
        <Description>
          Solderland Mines is a First Story Based NFT stake game. Our goal with
          this game is to create a truly quality NFT community and to enable
          other NFT projects and companies in the physical world to promote in
          the game. That's why we created a big planet for our Bunnies.
          Depending on the story we wrote, Bunnies work in the mines on the
          planet Solderland and earn rewards from the mines they work in. These
          rewards will be Solana , Solderland Token, NFT’s from other projects,
          whitelist spots for other projects, cash Prizes from other projects,
          Cash prizes from Company’s
        </Description>
        <S.WatchDemo>
          Check out our demo
          <S.ScrollDown></S.ScrollDown>
        </S.WatchDemo>

        {/* <S.PromotionArea>
          <S.PromotionHeader>
            <S.PromotionHeaderText>
              BUNNIES IN YOUR WALLET
            </S.PromotionHeaderText>
            <S.PromotionHeaderWalletArea>
              <S.PromotionHeaderWalletIcon></S.PromotionHeaderWalletIcon>
              <S.PromotionHeaderWalletIdText>
                5bZPq38Aa1UH8GPi8u...
              </S.PromotionHeaderWalletIdText>
            </S.PromotionHeaderWalletArea>
          </S.PromotionHeader>
          <S.PromotionCardArea>
            <S.PromotionCard>
              <S.PromotionCardImage src={WalletImage1}></S.PromotionCardImage>
              <S.PromotionCardButton>ASSIGNED</S.PromotionCardButton>
            </S.PromotionCard>
            <S.PromotionCard>
              <S.PromotionCardImage src={WalletImage2}></S.PromotionCardImage>
              <S.PromotionCardButton>ASSIGNED</S.PromotionCardButton>
            </S.PromotionCard>
            <S.PromotionCard>
              <S.PromotionCardImage src={WalletImage3}></S.PromotionCardImage>
              <S.PromotionCardButton>ASSIGNED</S.PromotionCardButton>
            </S.PromotionCard>
            <S.PromotionCard>
              <S.PromotionCardImage
                src={WalletImage4}
                assign
              ></S.PromotionCardImage>
              <S.PromotionCardButton assign>ASSIGN</S.PromotionCardButton>
            </S.PromotionCard>
            <S.PromotionCard>
              <S.PromotionCardText>
                Buy more NFT from secondary markets.
              </S.PromotionCardText>
              <S.PromotionIcons>
                <img src={CardLogo1} alt="Marketplace Icon" />
                <img src={CardLogo2} alt="Marketplace Icon" />
                <img src={CardLogo3} alt="Marketplace Icon" />
              </S.PromotionIcons>
            </S.PromotionCard>
          </S.PromotionCardArea>
          <S.PromotionFooter>
            <S.PromotionFooterLeft>
              <S.PromotionFooterLeftText>
                PLACE YOUR BUNNIES IN THE AREA YOU WANT TO STAKE
              </S.PromotionFooterLeftText>
            </S.PromotionFooterLeft>
            <S.PromotionFooterRight>
              <S.PromotionFooterRightTime>
                <span>2</span> Day <span>6</span> Hour <span>44</span> Minute{" "}
                <span>39</span> Second
              </S.PromotionFooterRightTime>
            </S.PromotionFooterRight>
          </S.PromotionFooter>
          <S.AssignButton>Assign</S.AssignButton>
          <S.DemoText>*This area is a visual demo </S.DemoText>
        </S.PromotionArea> */}

        {/* <Button>
          <span>Learn More</span>
        </Button> */}
      </Container>
      <div className="bg-video-wrap">
        <video src={Video} loop={true} muted={true} autoPlay={true}></video>
        <div className="overlay"></div>
      </div>
      {/* <S.MapPicture>
        <img src={MapBackground}></img>
      </S.MapPicture> */}
    </S.Section>
  );
};

export default WhatIsSolderland;
