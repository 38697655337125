import styled from "styled-components";
import { breakpoints } from "theme";

export const Card = styled.div`
  background: #08051b;
  border: 1px solid #202020;
  border-radius: 11px;
  z-index: 2;

  height: 250px;

  @media ${breakpoints.mobile} {
    &:first-child {
      margin-top: 250px;
    }
  }
`;

export const CardBackground = styled.div<{ active; background }>`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;

  display: flex;
  align-items: center;
  justify-content: center;

  mix-blend-mode: ${(props) => (props.active ? "" : "luminosity")};

  &::after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background-image: linear-gradient(
        270deg,
        #08051b 18.54%,
        rgba(0, 0, 0, 0) 50.15%
      ),
      url(${(props) => props.background});
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 0.3;
    border-radius: 10px;
    z-index: -1;
    @media ${breakpoints.mobile} {
      opacity: ${(props) => (props.active ? 1 : 0.3)};
      width: 100%;
    }
  }
`;

export const CardImage = styled.img<{ active: boolean }>`
  position: relative;
  object-fit: ${(props) => (props.active ? "cover" : "none")};
  z-index: 3;
  left: 30px;
  margin: auto;
  top: ${(props) => (props.active ? "-25px" : 0)};
  @media ${breakpoints.mobile} {
    position: absolute;
    display: ${(props) => (props.active ? "block" : "none")};
    left: calc(50% + 20px);
    transform: translateX(-50%);
    top: -260px;
    z-index: -1;
  }
`;

export const CardTextArea = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  flex-direction: column;
  width: 60%;
  margin: auto;
  padding: 0 30px;
  z-index: 5;
  text-align: left;

  @media ${breakpoints.mobile} {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

export const CardTitle = styled.h6`
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 23px;
  color: #7a91ff;
`;

export const CardDescription = styled.p`
  font-size: 15px;
  line-height: 20px;

  color: #ffffff;
`;
