import styled from "styled-components";
import { ReactComponent as DiscordIcon } from "assets/icons/Discord.svg";

import { breakpoints } from "theme";

export const Section = styled.section`
  position: relative;
  text-align: center;
  margin-top: 85px;
  width: 100%;
  z-index: 2;
`;

export const DiscordJoinButton = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  margin: auto;
  margin-top: 25px;
  padding: 20px 60px;
  gap: 50px;
  background: #5a6aef;
  border: 1px solid #6d7dff;
  border-radius: 10px;
  width: fit-content;

  &::after {
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    height: calc(100% + 20px);
    width: calc(100% + 20px);
    background: rgba(255, 255, 255, 0.09);
    border-radius: 10px;
  }

  @media ${breakpoints.mobile} {
    flex-direction: column;
    padding: 10px 25px;
    gap: 5px;
  }
`;

export const DiscordIconStyle = styled(DiscordIcon)`
  path {
    fill: white;
  }
`;
export const DiscordTextButton = styled.span`
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  color: #ffffff;
  text-transform: uppercase;
`;
