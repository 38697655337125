import * as S from "./styles";
import { Description, Title } from "styles/Global.styles";
import { features, mobileSize } from "variables";
import Container from "components/Container";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
const Features = ({ isMobile }) => {
  return (
    <S.Section id="utility">
      <Container>
        <Title>HOW CAN I EXPLORE SOLDERLAND</Title>
        <Description>
          As you explore Solderland, you can solve the mysteries of the map,
          contribute to the progress of the game, and in return, you can win
          rewards. Keep in mind that as Solderland is explored, bigger rewards
          and quests will appear.
        </Description>
        <S.ItemArea mobile={isMobile}>
          {isMobile ? (
            <MobileFeatureCards />
          ) : (
            features.map((item, index) => (
              <S.Item key={index}>
                <S.ItemTitle>{item.title}</S.ItemTitle>
                <S.ItemDescription>{item.description}</S.ItemDescription>
              </S.Item>
            ))
          )}
        </S.ItemArea>

        {/* <S.FindButton>Find out the details</S.FindButton> */}
      </Container>
    </S.Section>
  );
};

const MobileFeatureCards = () => {
  return (
    <Swiper
      modules={[Navigation]}
      navigation
      slidesPerView={1}
      slidesPerGroup={1}
      breakpoints={{
        [mobileSize]: {
          slidesPerView: 3,
          slidesPerGroup: 3,
          spaceBetween: 50,
        },
        1250: {
          slidesPerView: 4,
          slidesPerGroup: 4,
          spaceBetween: 50,
        },
      }}
      pagination={{ clickable: true }}
    >
      {features.map((item, index) => (
        <SwiperSlide key={index}>
          <S.Item>
            <S.ItemTitle>{item.title}</S.ItemTitle>
            <S.ItemDescription>{item.description}</S.ItemDescription>
          </S.Item>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default Features;
