import styled, { css } from "styled-components";

export const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: black;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  transition: transform 0.3s ease-in-out;
  width: 100%;
  div {
    padding: 2rem 0;
  }
`;

export const MenuLink = styled.a<{ soon: any }>`
  position: relative;
  color: white;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 0.1rem;
  color: white;
  text-decoration: none;
  transition: color 0.3s linear;
  ${(props) =>
    props.soon &&
    css`
      &::after {
        position: absolute;
        content: "soon";
        top: -10px;
        right: -40px;
        font-size: 9px;
        padding: 2px 4px;
        background: #2cd0ff;
        border-radius: 5px;
      }
    `}
`;
