import styled from "styled-components";

export const Section = styled.section`
  position: relative;
  z-index: 2;
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`;

export const Loading = styled.img`
  height: 300px;
  width: 300px;
`;

export const LoadingText = styled.h6`
  color: white;
  font-size: 25px;
`;
