import styled from "styled-components";
import HomePageBackground from "assets/images/home-background.jpg";
import ResultBackground from "assets/images/result-background.jpg";
import { breakpoints } from "theme";

export const FormSection = styled.section`
  position: relative;
  padding: 45px 60px;
  height: auto;
  min-height: 100%;

  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.67) 32.51%,
        #000000 106.11%
      ),
      url(${HomePageBackground});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;

    filter: grayscale(1);
    z-index: -1;
  }

  @media ${breakpoints.mobile} {
    padding: 25px 30px;
  }
`;

export const FormHeader = styled.header`
  display: flex;
  justify-content: space-between;
`;

export const BackHome = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    color: #fff;
    text-transform: uppercase;
  }
`;

export const FormHeaderSocialMedia = styled.div`
  display: flex;
  gap: 35px;
`;

export const FormContent = styled.main`
  display: flex;
  flex-direction: column;
  width: 650px;
  margin: 50px auto 0 auto;
  text-align: center;

  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const FormContentTitle = styled.h3`
  font-weight: 800;
  font-size: 30px;
  color: #5effe3;
  text-transform: uppercase;
`;

export const FormContentDescription = styled.p`
  font-weight: normal;
  font-size: 17px;
  color: #ffffff;
`;

export const FormArea = styled.div`
  margin-top: 40px;
  width: 100%;
  text-align: left;
`;

export const FormItem = styled.div`
  &:not(:first-child) {
    margin-top: 15px;
  }

  label {
    font-weight: 800;
    font-size: 16px;
    color: #ffffff;
  }

  small {
    font-weight: 500;
    font-size: 13px;
    color: rgba(255, 255, 255, 0.5);
    mix-blend-mode: screen;

    a {
      color: rgba(255, 255, 255, 0.5);

      font-weight: 800;
    }
  }
`;

export const FormItemInput = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  position: relative;
  margin-top: 10px;

  input {
    width: 100%;
    height: 100%;
    text-indent: 20px;

    background: rgba(255, 255, 255, 0.2);
    border: 1px solid rgba(255, 255, 255, 0.14);
    font-weight: 500;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);

    ::placeholder {
      font-weight: 500;
      font-size: 16px;
      color: rgba(255, 255, 255, 0.7);
      mix-blend-mode: screen;
      opacity: 0.7;
    }
  }

  span {
    position: absolute;
    padding: 0 18px;
    right: 0;

    font-weight: 500;
    font-size: 12px;
    color: #dd3f2f;
    mix-blend-mode: screen;

    &.optional {
      color: #ee9ff1;
    }
  }
`;

export const ApplyButton = styled.button`
  padding: 15px;
  background: #2bb9a0;
  border: 1px solid rgba(255, 255, 255, 0.14);
  width: 100%;

  font-weight: 800;
  font-size: 16px;
  color: #ffffff;
  text-transform: uppercase;
`;

export const Footer = styled.footer`
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 40px;

  @media ${breakpoints.mobile} {
    flex-direction: column;
    gap: 10px;
  }
`;

export const FooterItem = styled.a`
  position: relative;
  font-weight: 800;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;

  &:not(:first-child) {
    &::after {
      position: absolute;
      content: "";
      display: inline-block;
      top: 40%;
      left: -20px;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      background: #fd41fd;
    }
  }
  @media ${breakpoints.mobile} {
    &::after {
      display: none !important;
    }
  }
`;

/* Form Result Section */
export const FormResultSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 45px 60px;

  height: auto;
  min-height: 100%;

  &:after {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.67) 32.51%,
        #000000 106.11%
      ),
      url(${ResultBackground});
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;

    filter: grayscale(1);
    z-index: -1;

    @media ${breakpoints.mobile} {
      background-size: cover !important;
      background-position: 90% 0;
    }
  }

  height: 100vh;

  @media ${breakpoints.mobile} {
    padding: 25px 30px;
  }
`;

export const FormResultWrapper = styled.div`
  width: 665px;
  margin: auto;
  text-align: center;
  @media ${breakpoints.mobile} {
    width: 100%;
  }
`;

export const FormResultTitle = styled.p`
  font-weight: 800;
  font-size: 30px;
  color: #5effe3;
`;

export const FormResultDescription = styled.p`
  margin-top: 15px;
  font-weight: normal;
  font-size: 18px;
  color: #ffffff;
  line-height: 25px;
`;

export const FormResultSocialMedia = styled(FormHeaderSocialMedia)`
  justify-content: center;
  margin-top: 35px;
`;

export const FormResultDiscoverMode = styled(ApplyButton)`
  margin-top: 45px;
  width: auto;
  padding: 20px 80px;
  border-radius: 5px;
`;

export const FormResultBackHome = styled(BackHome)`
  justify-content: center;
  margin-top: 65px;
`;

export const ErrorMessage = styled.span`
  display: block;
  font-size: 12px;
  color: #ff9494;
  margin-bottom: 10px;
`;
