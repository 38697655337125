import { bool } from "prop-types";
import { StyledMenu, MenuLink } from "./styles";

import { menu } from "variables";

const Menu = ({ open, ...props }) => {
  const isHidden = open ? true : false;
  const tabIndex = isHidden ? 0 : -1;

  return (
    <StyledMenu open={open} aria-hidden={!isHidden} {...props}>
      {menu.map((item, index) => (
        <div key={index}>
          <MenuLink href={item.href} tabIndex={tabIndex} soon={item.soon}>
            {item.title}
          </MenuLink>
        </div>
      ))}
    </StyledMenu>
  );
};

Menu.propTypes = {
  open: bool.isRequired,
};

export default Menu;
