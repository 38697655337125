import styled, { css } from "styled-components";
import { breakpoints } from "theme";

export const CollapseArea = styled.div`
  background: rgba(3, 1, 14, 0.7);
  width: 100%;
  margin: auto;
  margin-top: 30px;
  cursor: pointer;
  border-radius: 10px;

  &:not(:first-child) {
    margin-top: 10px;
  }
`;

export const CollapseHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 50px;
  gap: 10px;

  @media ${breakpoints.mobile} {
    border-radius: 10px;
    background: #282249;
  }
`;

export const CollapseTitle = styled.span`
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: left;
`;

export const CollapseIcon = styled.div<{ active: boolean }>`
  width: 15px;
  height: 20px;
  position: relative;
  display: inline-block;

  &::before,
  &:after {
    content: "";
    position: absolute;
    width: 15px;
    height: 3px;
    top: calc((1em / 2) - 0.08em);
    background-color: white;
    transition: 0.3s ease-in-out all;
    border-radius: 0.03em;
  }

  &::after {
    transform: rotate(90deg);
  }

  ${(props) =>
    props.active &&
    css`
      &::after {
        transform: rotate(180deg);
      }
      &::before {
        transform: rotate(90deg) scale(0);
      }
    `}
`;
export const CollapseContent = styled.div`
  position: relative;
  transition: 0.3s ease-in-out all;
  overflow: hidden;

  padding: 30px 50px;

  text-align: left;
  left: 10px;

  color: white;

  @media ${breakpoints.mobile} {
    background: rgba(40, 34, 73, 0.15);
  }
`;
