import * as S from "./styles";
import { Title, Description } from "styles/Global.styles";
import FolkCard from "./FolkCard";
import Container from "components/Container";

import { folkOfSolderland } from "variables";

const Folk = () => {
  return (
    <S.Section id="folk">
      <Container>
        <Title>Folks Of Solderland</Title>
        <Description>
          4 races live on Solderland. Each race has a different mission and
          abilities in Solderland Metaverse.
        </Description>
        <S.ItemArea>
          {folkOfSolderland.map((item, index) => (
            <FolkCard key={index} {...item}></FolkCard>
          ))}
          {/* {isMobile ? (
            <MobileFolkCard />
          ) : (
            
          )} */}
        </S.ItemArea>
      </Container>
    </S.Section>
  );
};

export default Folk;
