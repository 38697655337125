import styled from "styled-components";
import HomePageBackground from "assets/images/home-background.jpg";

import { Button } from "styles/Global.styles";
import { Description } from "styles/Global.styles";

import { breakpoints } from "theme";

export const Section = styled.section<{ isMobile: boolean }>`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  background: linear-gradient(
      0deg,
      rgba(11, 7, 31, 0.71),
      rgba(11, 7, 31, 0.71)
    ),
    url(${HomePageBackground});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;

  @media ${breakpoints.mobile} {
    height: 100%;
    padding-bottom: 30px;
  }
`;

export const SectionWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

// export const MoonImage = styled.img`
//   margin-top: 100px;
//   width: 100%;
//   height: 100%;
//   z-index: 2;
// `;

// export const BottomImage = styled.div`
//   display: block;
//   width: 100%;
//   height: 450px;
//   margin-top: 100px;
//   background-image: url(${BunniesMoonBackground});
//   background-repeat: no-repeat;
//   background-size: 100% 100%;
//   background-position: 0 0;
// `;

export const TitleWrapper = styled.div`
  background: rgba(0, 0, 0, 0.8);
  padding: 10px;
  width: 75%;
  margin: auto;

  @media ${breakpoints.mobile} {
    width: 100%;
    margin-top: 85px;
  }
`;

export const DescriptionClass = styled(Description)`
  margin-top: 10px;
`;

export const Text = styled.div`
  position: relative;
  z-index: 3;
`;

export const ButtonStyle = styled(Button)`
  padding: 15px 50px;
  background: linear-gradient(0deg, #5aa7ff, #5aa7ff), #c4c4c4;
  border-radius: 3px;
  z-index: inherit;

  span {
    font-weight: 900;
    font-size: 22px;
    color: #ffffff;
    -webkit-text-fill-color: #fff;

    background: none;
  }

  &::after {
    position: absolute;
    content: "";
    left: -10px;
    top: -10px;
    background: rgba(255, 255, 255, 0.17);
    border: none;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    z-index: -1;
    border-radius: 6px;
  }
`;
