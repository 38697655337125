import styled, { css } from "styled-components";
import { breakpoints } from "theme";

const TitleSpan = styled.span`
  background: linear-gradient(270deg, #ff35fe 0%, #0edbff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;
export const Title = styled(TitleSpan)<{ blackBg?: boolean }>`
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  font-weight: 900;
  font-size: 41px;
  /* 
  ${(props) =>
    props.blackBg &&
    css`
      background: black;
    `} */

  width: 90%;

  z-index: 3;

  @media ${breakpoints.mobile} {
    font-size: 30px;
    width: 100%;
  }
`;

export const Description = styled.p<{ bold?: boolean }>`
  position: relative;
  color: white;
  line-height: 33px;
  font-weight: ${(props) => (props.bold ? "bold" : "normal")};
  font-size: 22px;
  margin-top: 10px;
  z-index: 3;
  width: 100%;
  margin: auto;

  @media ${breakpoints.mobile} {
    line-height: 30px;
    font-size: 18px;
    width: 100%;
  }
`;

export const Button = styled.a`
  position: relative;
  margin-top: 40px;

  display: inline-block;

  font-weight: 600;
  font-size: 25px;
  text-transform: uppercase;

  border: 1px solid rgba(228, 61, 227, 0.4);
  border-radius: 5px;

  padding: 10px 80px 20px 80px;

  z-index: 3;

  /* -webkit-animation-name: spaceboots;
  -webkit-animation-duration: 0.8s;
  -webkit-transform-origin: 50% 50%;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear; */

  &::after {
    position: absolute;
    content: "";
    right: -10px;
    top: -10px;
    width: 100%;
    height: 100%;
    border: 1px solid #4bb1ff;
    border-radius: 5px;
  }

  /* &:hover {
    animation: hue 20s infinite linear;
  } */

  span {
    background: linear-gradient(
      270deg,
      #e54de5 0%,
      rgba(185, 255, 0, 0.78) 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  /* transition: all 5s ease-in-out;

  &:hover {
    box-shadow: 0 0 11px #e54de5;
    padding-top: 15px;
    &::after {
      right: 0;
      top: 0;
    } */
  /* } */

  @media ${breakpoints.mobile} {
    font-size: 15px;
  }
`;

/* ### Write Area ### */
export const WriteContent = styled.div`
  position: relative;
  width: 60%;
  margin: 100px auto 0 auto;
  z-index: 3;
  text-align: center;

  @media ${breakpoints.mobile} {
    width: 100%;
    text-align: left;
  }
`;

export const WriteContentDescription = styled(Description)`
  margin-top: 20px;
  width: 100%;
`;

export const WriteContentImage = styled.img`
  margin-top: 20px;
  width: 100%;
  height: 100%;
`;
